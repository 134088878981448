.barra-inferior {
  width: 100%;
  background-color: whitesmoke;
  bottom: 0;
  left: 0;
  text-align: center;
  clear: both;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.img-footer {
  vertical-align: middle;
}

@media (min-width: 1200px) {
  .barra-inferior {
    position:fixed;
  }
  }

