.barra-superior {
  width: 100%;
  height: auto;
  background-color: whitesmoke;
  padding: 1px 0px;
  text-align: center;
  display: inline-block;
  flex-wrap: wrap;
}



