.app {
  background-color: black;
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  text-align: center;
}

.body-content {
  margin-top: 20px;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
  color: black;
}

.subtitle {
  font-family: "Roboto Mono", monospace;
  font-weight: 100;
}

.title {
  font-family: "Courier Prime", monospace;
  font-weight: 500;
}

.align-center {
  text-align: center;
}

.div-card-extras {
  margin: 20px 0px 20px 0px;
}

.app .card-extras {
  border: none;
  border-radius: 20px;
  margin: 0 auto;
}

.app .card-images {
  border-radius: 20px;
}

/* CARDS */
.ficha-title {
  width: 216px;
  height: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #060b25;
  display: flex;
}

.imagen-label {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.display-none {
  display: none;
}

.textos-flotantes {
  position: absolute;
  top: 0;
  max-width: 100%;
}

.texto-flotante-title {
  background: rgba(247, 183, 183, 0.9);
  border-radius: 10px;
}

.texto-flotante {
  border-radius: 10px;
}

.upper-text {
  width: 200px;
  height: 30px;
  margin: 0px 50px 30px 50px;
  background: rgba(200, 198, 196, 0.9);
}

.down-text {
  width: 200px;
  padding: 2px 0px;
  margin: auto;
  margin-bottom: 50px;
  background: rgba(255, 255, 255, 0.9);
}

.down-text:hover {
  background: rgba(247, 183, 183);
  box-shadow: 0px 16px 24px rgba(18, 25, 84, 0.07);
}

.breakpoint-img {
  width: 300px;
}

.img-card {
  max-width: 100%;
  border-radius: 20px;
}

@media (min-width: 1200px) {
  .app {
    min-height: 100vh;
  }

  .app .card-extras {
    height: calc(100vh - 80px);
    padding-top:40px;
    display: flex;
    max-height: 440px;
  }

  .imagen-label {
    margin: 8px;
  }

  .breakpoint-img {
    width: 1200px;
  }
}